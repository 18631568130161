window.jQuery = require('jquery');
require('corejs-typeahead');

//var typeahead = require('corejs-typeahead');
window.Bloodhound = require('bloodhound-js');


jQuery(document).ready(function($) {

    var pages = new Bloodhound({
        datumTokenizer: function(countries) {
            return Bloodhound.tokenizers.whitespace(title);
        },
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/search/%QUERY%',
            wildcard: '%QUERY%'
        }
    });

    pages.initialize();

    $('.typeahead').typeahead(
        { hint: true,
            highlight: true,
            minLength: 1
        },
        {
            name: 'pages',
            displayKey: function(pages) {
                //return pages.title;
            },
            source: pages.ttAdapter(),
            templates: {
                header: [
                    '<div class="list-group search-results-dropdown">'
                ],
                empty: [
                    '<div class="list-group search-results-dropdown"><div class="list-group-item">Ingen träff</div></div>'
                ],
                suggestion: function (data) {
                    var result = '<a href="' + data.url + '" class="list-group-item search-title"><strong>' + data.title + '</strong>';
                    if(data.description !== '') {
                        result += '<br><span class="search-desc">' + data.description + '</span>';
                    }
                    result += '</a>';

                    return result;
                }
            }
        });

    $('input.typeahead').on('typeahead:selected', function(event, selection) {
        document.location = selection.url;
    });
});